<template>
    <div class="home-container">
        <!-- 搜索 + 轮播信息 -->
        <div class="pad-tb-4 home-top">
            <div class="def-container home-top-inner">
                <div class="top-left">
                    <search></search>
                </div>
                <div class="pad-2 top-right home-top-notify">
                    <div class="top flex-row-between">
                        <div class="title">通知公告</div>
                        <div
                            class="more"
                            @click="noticeMore"
                        >更多+</div>
                    </div>
                    <div
                        class="bottom"
                        ref="noticeRef"
                    >
                        <div
                            class="title-container"
                            v-for="(item, index) in cpu_noticeList"
                            :key="index"
                        >
                            <div
                                class="pointer title-item"
                                @click="noticeTodo(item)"
                            >
                                <div class="title-name">{{ item.name }}</div>
                                <p class="title-time">{{ item.policypubdate }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="!userInfo"
                    class="wrap-qr"
                    @click="toQrLogin"
                >
                    <img :src="require('@/assets/images/home/loginErweima_helf.png')" />
                    <p>
                        <span>点击</span>
                        <br />
                        <span>扫码登录</span>
                    </p>
                </div>
            </div>
        </div>

        <PolicyProgramEarly></PolicyProgramEarly>
    </div>
</template>

<script>
// import SwiperBox from "@/components/swiperBox.vue"; // 展示卡片 3
// import ColumnTitle from "@/components/columnTitle.vue"; // 栏目标题 接收string 留有 右边 slot
// import ColumnBox from "../../components/columnBox"; // 申报项目，智能推荐卡片
// import PublicForm from "@/components/publicForm"; // 公开公示表单
// import PlatformStatistics from './platformStatistics.vue';  // 统计
import Search from "./search.vue";
import PolicyProgramEarly from '@/views/findList/PolicyProgramEarly'
import { mapGetters } from "vuex";
// import DeclareCell from "@/components/declareCell"; // 申报项目，智能推荐卡片
import { Loading } from "element-ui";

export default {
    name: "home",
    components: {
        // SwiperBox,
        // SwiperItemList,
        // ColumnTitle,
        // ColumnBox,
        // PublicForm,
        // PlatformStatistics,
        Search,
        PolicyProgramEarly,
        // DeclareCell,
    },
    computed: {
        ...mapGetters({
            userInfo: 'getUserInfo',
            hNavAction: 'hNavAction',
        }),
        cpu_noticeList() {
            return this.noticeList.slice(0, 4)
        },
    },
    data() {
        return {
            collectList: [],
            dialogVisible: false,
            search: "",
            // 通知公告列表
            noticeList: [],
            // 联系
            toolbarRightList: [
                {
                    name: "政策匹配", // name
                    path: "http://fsfczj.foshan.gov.cn/#/intelligence", // 转跳信息
                },
                {
                    name: "国家政务服务中心", // name
                    path: "http://fsfczj.foshan.gov.cn/#/intelligence", // 转跳信息
                },
                {
                    name: "广东省中小企业", // name
                    path: "http://fsfczj.foshan.gov.cn/#/intelligence", // 转跳信息
                },
            ],
            // 展示 卡片
            swiperList: [
                {
                    title: "小微企业和个体工商户服务专栏",
                    subtitle: "小微企业和个体工商户服务专栏",
                    bc: require("@/assets/images/tips_1.png"),
                    id: "1",
                },
                {
                    title: "粤港澳大湾区服务专栏",
                    subtitle: "开创融合新格局",
                    bc: require("@/assets/images/tips_2.png"),
                    id: "2",
                },
                {
                    title: "其他惠企服务专区",
                    subtitle: "企业融资与金融普惠",
                    bc: require("@/assets/images/tips_3.png"),
                    id: "3",
                },
            ],
            swiperItemList: [
                {
                    title: "小微企业和个体工商户服务专栏",
                    subtitle: "小微企业和个体工商户服务专栏",
                    bc: require("@/assets/images/tips_1.png"),
                    id: "1",
                },
                {
                    title: "粤港澳大湾区服务专栏",
                    subtitle: "开创融合新格局",
                    bc: require("@/assets/images/tips_2.png"),
                    id: "2",
                },
                {
                    title: "其他惠企服务专区",
                    subtitle: "企业融资与金融普惠",
                    bc: require("@/assets/images/tips_3.png"),
                    id: "3",
                },
                {
                    title: "其他惠企服务专区",
                    subtitle: "企业融资与金融普惠",
                    bc: require("@/assets/images/tips_3.png"),
                    id: "3",
                },
            ],
            // 热门申报项目卡片
            declaList: [],
            // 智能推荐
            recomList: [],
            // 公告列表数据
            publicList: [],
        };
    },
    mounted() {
        // this.getEnterpriseList();	// 用于判断是否需要提示补充基本信息
        this.getCollectList();

        // UI改版后，暂不需要热门申报了
        // this.getHotDecla();

        // this.getPublicList();
        this.getRecomList();
        this.getPublicList();

    },
    methods: {
        /**
         * @description 前往二维码登录页
         */
        toQrLogin() {
            this.$router.push({
                path: '/login'
            })
        },
        /**
         * 获取通知公告
         */
        getPublicList() {
            let loadingnoticeRef = Loading.service({
                target: this.$refs.noticeRef,
            });
            // type 1项目政策 2通知公告 3公开公示
            let params = {
                pageNum: 1,
                pageSize: 20,
                type: 2,
            };
            this.$httpApi
                .get(`/dev-api/document/list`, params)
                .then((res) => {
                    console.log("通知公告列表:", res);
                    if (res.code === 200) {
                        loadingnoticeRef.close();
                        // this.noticeList = res.rows;
                        let target = JSON.parse(JSON.stringify(res.rows))
                        target.sort(function (a, b) {
                            return a.policypubdate < b.policypubdate ? 1 : -1
                        });
                        this.noticeList = target;
                        console.log("处理后的数组>>>", target)
                    }
                })
                .catch((err) => {
                    loadingnoticeRef.close();
                });
        },
        /**
         * 转跳到通知公告列表
         */
        noticeMore() {
            let { href } = this.$router.resolve({
                path: "/noticeNotice",
            });
            window.open(href, "_blank");
        },
        /**
         * 转跳到通知公告详细
         */
        noticeTodo(val) {
            let that = this;
            // console.log("点击,转跳", val);
            // this.$router.push({
            //   path: "/notice/detail/" + val.id+"?type="+that.itemType
            // });
            let { href } = that.$router.resolve({
                path: "/notice/detail/" + val.id + "?type=" + that.itemType,
            });
            window.open(href, "_blank");
        },
        /**
         * 热门申报
         */
        getHotDecla() {
            let date = new Date();
            let year = date.getFullYear();
            let pageNum = 0;
            let pageSize = 100;
            let params = { pageNum: pageNum, pageSize: pageSize, year: year };
            this.$httpApi
                .get("/dev-api/basicinfo/baseitem/hot-list", params)
                .then((res) => {
                    console.log("热门申报:", res);
                    if (res.code == 200) {
                        // let resList = res.data
                        // for ( let i = 0 ; i < resList.length ; i++ ) { 
                        // 	let objitem = resList[i]
                        // 	for ( let el in objitem ) {
                        // 		el = this.lowerJSONKey(el)
                        // 		console.log("el", el)
                        // 	}
                        // }
                        // console.log(">>>", res.rows)
                        this.declaList = res.data;
                    }
                })
                .catch((err) => { });
        },
        // 小写转换
        lowerJSONKey(jsonObj) {
            for (var key in jsonObj) {
                jsonObj['"' + key.toLowerCase() + '"'] = jsonObj[key];
                delete jsonObj[key];
            }
            return jsonObj;
        },

        /**
         * 公告详细信息
         * @param {string} id 传项目id
         */
        getNoticeInfo(id) {
            if (id) {
                this.$httpApi.get(`/dev-api/document/${id}`, {}).then((res) => {
                    // console.log("公告详细信息:", res);
                });
            }
        },

        /**
         * 获取搜索信息
         */
        getSearchInfo(...el) {
            console.log("获取的搜索条件", ...el);
        },

        /**
         * 热门申报转跳
         */
        swiTo(e) {
            console.log("卡片转跳信息", e);
            sessionStorage.setItem("specialColumn", JSON.stringify(e));
            let id = e.id;
            if (id == 3) {
                let href =
                    "http://zxr.gdjr.gd.gov.cn/portal/static/cofinance/index.html";
                window.open(href, "_blank");
            } else if (id == 2) {
                this.$router.push({
                    path: `/specialGangaoyueColumn/${id}`,
                });
            } else {
                this.$router.push({
                    path: `/specialColumn/${id}`,
                });
            }
        },

        /**
         * 热门项目收藏点击
         */
        clickIsFollow(item) {
            //判断是否登录
            if (this.$store.getters.getUserInfo) {
                //登录
            } else {
                //未登录
                this.dialogVisible = true;
            }
        },
        /**
         * 登录提示框点击确定登录，跳转登录页面
         */
        gotoLogin() {
            console.log("跳转登录页面");
            this.$router.push({
                path: "/login",
            });
        },

        /**
         * 申报 + 推荐 卡片点击事件
         * @param {object} item 卡片信息
         * @return 转跳到申报详细
         */
        clickCell(item) {
            this.$store.commit("updateCurrentGuideId", item.ID || item.id);
            // if (["1", "2"].indexOf(item.ITEMTYPE || item.itemtype) > -1) {
            //   this.$router.push({
            //     path: "/guideShenpi",
            //     query: {
            //       name: item.ITEM_BASE_NAME || item.itemBaseName,
            //     },
            //   });
            // } else {
            //   this.$router.push({
            //     path: "/guideSuqiu",
            //   });
            // }
            this.$router.push({
                path: "/guideSuqiu",
                query: {
                    name: item.ITEM_BASE_NAME,
                    itemType: item.itemtype || '',
                    isAdded: item.isAdded || ''
                },
            });
            // 修改 header nav 状态
            this.$store.commit("updateHNavAction", "/projectList");
        },
        /**
         * 申报栏  获取更多 转跳到申报栏目
         */
        getMore() {
            // 修改 header nav 状态
            this.$store.commit("updateHNavAction", "/findList");
            this.$router.push({
                path: "/findList",
            });
        },

        /**
         * 智能推荐 刷新
         * @return {array} 随机数组供显示
         */
        getUpdata() {
            /* 重新获取信息 */
            this.getRecomList();
            /* 筛选信息条数 */
            // aryanimals.slice(0, 3)     // 浅拷贝
        },
        getRecomList() {
            let userInfo = this.$store.getters.getUserInfo;
            if (userInfo) {
                // console.log("用户统一社会信用代码：",userInfo.shxydm)
                let params = {
                    shxydm: userInfo.tyshxydm,
                };
                // console.log("智能推荐参数：", params);
                this.$httpApi
                    .get("/dev-api/basicinfo/baseitem/getEnterPermitItem", params)
                    .then((res) => {
                        console.log("智能推荐：", res);
                        this.recomList = res.data.items.slice(0, 3);
                    })
                    .catch((err) => {
                        console.log("获取智能推荐失败：", err);
                    });
            } else {
                this.recomList = [];
            }
        },
        //查询所有的收藏列表,之后需要比对事项的收藏状态
        getCollectList() {
            if (this.$store.getters.getUserInfo) {
                let identify = this.$store.getters.getUserInfo.shxydm;
                let param = {
                    identify: identify,
                    type: "1,2,3,4,5,6",
                    pageNum: 1,
                    pageSize: 100,
                };
                let url = "/dev-api/collection/list";

                this.$httpApi
                    .get(url, param)
                    .then((res) => {
                        console.log("收藏的列表" + res)
                        if (res.code == 200) {
                            this.collectList = res.rows;

                        }
                    })
                    .catch((err) => { });
            }
        },
        //比对收藏列表,判断是否收藏
        isItemCollect(item) {
            let list = this.collectList;

            for (let i = 0; i < list.length; i++) {
                let collectItem = list[i];
                if (item.ID == collectItem.collectionId) {
                    console.log(item.name + ":已收藏");
                    return collectItem;
                }
            }
            return null;
        },
    },
    watch: {
        userInfo: {
            immediate: true,
            handler: function (newVal, oldVal) {
                // console.log('userInfo:', newVal)
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/styles/com/theme.scss";

.home-container {
    position: relative;
}

.home-top {
    position: relative;
    background: url("../../assets/images/bananer_bottom.png") center / 100% 100%
        no-repeat;
    .top-right {
        min-width: 0;
        background-color: rgba(#007bce, 0.5);
    }
    .home-top-notify {
        position: relative;
        z-index: 1;
        .top {
            border-bottom: 1px solid #ffffff;

            .title {
                font-weight: 700;
                color: #ffffff;
                font-size: 16px;
                line-height: 32px;
            }
            .more {
                color: #ffffff;
                font-size: 14px;
                line-height: 32px;
                cursor: pointer;
            }
        }
        .bottom {
            .title-container {
                margin-top: 10px;
                font-size: 14px;

                .title-item {
                    display: -webkit-flex;
                    display: flex;
                }
                .title-name,
                .title-time {
                    color: #ffffff;
                }
                .title-name {
                    flex: 1;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
                .title-time {
                    margin-left: 40px;
                }
                &:hover {
                    color: #2d81ff;
                }
            }
        }

        ::v-deep .el-loading-mask {
            background: none;
        }
    }
}
.home-top-inner {
    display: -moz-grid;
    display: -ms-grid;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 40px;
}
.home-content {
    .decla-recom {
        width: 100%;
    }
    /* 项目申报 */
    .colu-box {
        width: 585px;
        height: 170px;
        margin-bottom: 10px;
    }
    /* 智能推荐 */
    .colu-recom-box {
        width: 380px;
        height: 270px;
    }
    .public-announcement {
        width: 100%;
    }
}

.home-items-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    .item {
        width: calc((1200px - 40px) / 3);
        box-sizing: border-box;
        margin-right: 20px;
        margin-bottom: 20px;
    }
    .item:nth-child(3n + 3) {
        margin-right: 0px;
    }
    .rec-item {
        width: calc((1200px - 40px) / 3);
        box-sizing: border-box;
        margin-right: 20px;
    }
    .rec-item:nth-child(3n + 3) {
        margin-right: 0px;
    }
}
.wrap-qr {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    z-index: 0;

    p {
        position: absolute;
        bottom: 0;
        left: 0;
        line-height: 1;
        color: #ffffff;
        transform: translate(0, -5px);
    }
    &:hover p {
        color: $col-theme;
    }
}
@media screen and (min-width: 1600px) {
    .home-top-inner {
        gap: 0 160px;
    }
}
</style>
