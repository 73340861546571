<template>
  <div class="flex-column-between home-search">
    <div class="home-topL-search">
      <div class="input-search-bar">
        <div style="float: left">
          <!-- <el-select
            style="width: 160px"
            v-model="selectValue"
            @change="selectChange"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              style="font-size:18px"
            >
            </el-option>
          </el-select>
          <el-divider direction="vertical"></el-divider> -->
        </div>
        <el-input
          class="input-search"
          style="font-size: 16px;width:80%"
          v-model.trim="inputKeyWord"
          @keyup.enter.native="toSearch"
          placeholder="请输入你想要办理的业务"
        >
        </el-input>
        <el-button class="input-search-btn" type="primary" @click="toSearch"
          ><img src="~@/assets/deyangImg/search.png" alt="" />
        </el-button>
      </div>
      <!-- 热搜词 -->
      <div
        class="input-tips flex-row flex-start pointer"
        v-show="typeIndex == 0"
      >
        <!-- <span class="flex-row">
          <div
            :class="['input-tips-item', index != 0 ? 'border-left' : '']"
            v-for="(item, index) in tipsList"
            :key="index"
            @click="tipTo(item)"
          >
            {{ item.word || "" }}
          </div>
        </span> -->
      </div>
    </div>
  </div>
</template>

<script>
import { FormSelect, FormSelectAutoComplete } from "@/components/form/index.js";

const HOME_TITLE = require("@/assets/images/banner_text.png");

export default {
  components: {
    // FormSelect, FormSelectAutoComplete,
  },
  props: {
    typeList: {
      type: Array,
      default: function () {
        return [
          {
            name: "按关键字",
            placeholder: "政策/项目/通知/公示",
            searchName: "搜 索",
            value: "sortByKeyWord",
          },
          {
            name: "按企业",
            placeholder: "企业名称，进行政策匹配",
            searchName: "匹 配",
            value: "sortByCompany",
          },
        ];
      },
    },
  },
  data() {
    return {
      flag: false,
      searchType: "sortByKeyWord", // 搜索类型{'sortByKeyWord':按关键字, 'sortByCompany':按企业}
      tyshxydm: "", // 企业统一社会信用代码
      companyData: [], // 企业列表
      inputKeyWord: "",
      searchSelect: "按关键字",
      typeIndex: 0, // 0按关键字 1按企业
      showType: {},
      tipsList: [], // 热搜词
      enterpriseData: [],
      selectEnterprise: null, // 选择的社会信用代码的item
      selectValue: "1",
      options: [
        {
          value: "1",
          label: "全部",
        },
        {
          value: "2",
          label: "政策库",
        },
        {
          value: "3",
          label: "企业办事库",
        },
        // {
        //   value: "4",
        //   label: "一件事一次办",
        // },
        // {
        //   value: "5",
        //   label: "政策项目",
        // },
        {
          value: "6",
          label: "通知公告",
        },
      ],
    };
  },
  computed: {
    cpu_homeTitleSrc() {
      return HOME_TITLE;
    },
  },
  created() {
    this.showType = this.typeList[0];
    this.getHotWords();
    if (sessionStorage.getItem("menuIndex")) {
      this.selectValue = sessionStorage.getItem("menuIndex");
    }
  },
  mounted() {
    // let self = this;
    // let count = 5000
    // setInterval(function () {
    //     self.fade(self.flag)
    // }, count);
    // setTimeout(()=>{
    //     count=count+5000
    // },5000)
  },
  methods: {
    // 文字淡入淡出
    fade(flag) {
      if (flag) {
        this.$nextTick(() => {
          if (document.getElementById("bannertxt1")) {
            document.getElementById("bannertxt1").className = "fade";
          }
          if (document.getElementById("bannertxt2")) {
            document.getElementById("bannertxt2").className = "test";
          }
        });
      } else {
        this.$nextTick(() => {
          if (document.getElementById("bannertxt1")) {
            document.getElementById("bannertxt1").className = "test";
          }
          if (document.getElementById("bannertxt2")) {
            document.getElementById("bannertxt2").className = "fade";
          }
        });
      }
      this.flag = !this.flag;
    },
    /**
     * @description: 联想企业
     * @param {String} keyword 搜索关键字
     * @param {Function} cb 回调函数
     */
    searchComponay(keyword, cb) {
      let url = "/dev-api/elasticMgr/getEnterpriseDatas";
      let params = {
        pageNum: 1,
        pageSize: 10,
        keyWords: keyword,
      };
      this.companyData = [];
      this.$httpApi
        .get(url, params)
        .then((res) => {
          console.log("queryCompany:", res);
          if (res.code == 200) {
            this.companyData = (res.data || []).map((cv) => {
              return {
                ...cv,
                label: cv.name.replace(/<[^<>]+>/g, ""),
              };
            });
            cb(res.data || []);
          } else {
            cb([]);
          }
        })
        .catch((err) => {
          console.log("queryCompany error:", err);
          cb([]);
        });
    },
    getHotWords() {
      this.$httpApi
        .get("/dev-api/system/hotWords", {})
        .then((res) => {
          if (res.code == 200) {
            let list = res.rows;
            console.log("热门词:", list);
            if (list.length === 0) {
              return;
            }
            if (list.length <= 3) {
              this.tipsList = list || [];
            } else {
              this.tipsList = [list[0], list[1], list[2]];
            }
          }
        })
        .catch((err) => {});
    },
    querySearchAsync(queryString, cb) {
      this.queryStringEnterprise(queryString, cb);
    },
    queryStringEnterprise(queryString, cb) {
      this.enterpriseData = [];
      let url = `/dev-api/cfgpermitconfig/query/data/find/cfg_permitinfo_andzchx_list`;
      let params = {
        keyword: queryString,
        itemtype: "2",
        release: "1",
        ["PAGE_CUR"]: "1",
        ["PAGE_ROWS"]: "",
      };
      this.$httpApi
        .post(url, params)
        .then((res) => {
          let result = [];
          let list = res.data;
          this.enterpriseData = list;
          for (const i in list) {
            let name = list[i].dept_name;
            //   let n1 = name.replace(/<\/?.+?>/g, "");
            //   let n2 = n1.replace(/ /g, "");
            // n2 += `(${list[i].shxydm})`;
            list[i].title = name;
            result.push({ value: name });
          }
          if (result.length > 0) {
            cb(result);
          } else {
            cb([{ value: "暂无数据" }]);
          }
        })
        .catch((err) => {});
    },
    // 选择autocomplete下拉选项
    handleSelect(item) {
      this.inputKeyWord = item.value;
      if (this.typeIndex == 1) {
        for (const i in this.enterpriseData) {
          const element = this.enterpriseData[i];
          if (item.value === element.title) {
            console.log("选择的", element);
            this.selectEnterprise = element;
          }
        }
      }
      this.gotoSearchResult();
    },
    /* 开始搜索 */
    toSearch() {
      // sessionStorage.setItem(
      //     "titleTypeBack",
      //     sessionStorage.getItem("titleType")
      //   );
      // //sessionStorage.setItem("titleType", "1");
      if (this.typeIndex == 1) {
        this.queryStringEnterprise(this.inputKeyWord, () => {});
      } else {
        this.gotoSearchResult();
      }
    },
    /* 热搜词转跳 */
    tipTo(e) {
      this.inputKeyWord = e.word;
      this.gotoSearchResult();
    },
    gotoSearchResult() {
      // if (this.inputKeyWord.trim().length == 0) {
      //   this.$message({
      //     type: "warning",
      //     message: "请输入您要搜索的内容",
      //   });
      //   return;
      // }
      if (this.typeIndex == 0) {
        let params = {
          keyWords: this.inputKeyWord,
          type: this.typeList[this.typeIndex].value,
        };
        sessionStorage.setItem("searchData", JSON.stringify(params));
        this.$router.push({
          name: "searchList",
          params: { keyWords: this.inputKeyWord },
        });
        this.inputKeyWord = "";
      } else {
        if (this.selectEnterprise == null || this.selectEnterprise.id == null) {
          this.$message({
            type: "error",
            message: "企业信息错误, 请重新输入",
          });
          return;
        }
        let params = {
          keyWords: this.inputKeyWord,
          type: this.typeList[this.typeIndex].value,
          selectEnterprise: this.selectEnterprise,
        };
        sessionStorage.setItem("searchCompanyData", JSON.stringify(params));
        let { href } = this.$router.resolve({
          // path: "/policyMatch",
          name: "searchCompany",
          query: params,
        });
        window.open(href);
        this.inputKeyWord = "";
      }
    },
    selectChange(val) {
      sessionStorage.setItem("menuIndex", val);
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/com/theme.scss";
#bannertxt1 {
  position: absolute;
  font-size: 30px;
  color: #fff;
  transition: all 4s;
  background-image: url("~@/assets/images/txt1.png");
  background-size: cover;
  background-repeat: no-repeat;
  /*过渡时间*/
}
#bannertxt2 {
  position: absolute;
  font-size: 30px;
  color: #fff;
  top: 40%;
  transition: all 4s;
  background-image: url("~@/assets/images/indexSwiper.png");
  background-size: contain;
  background-repeat: no-repeat;
  /*过渡时间*/
}
.fade {
  opacity: 0;
  transition: opacity 4s;
}
.home-search {
  padding: 15px;
}
.home-topL-title {
  align-self: flex-start;
  margin-bottom: 10px;
}
.home-topL-search {
  width: 100%;
  height: 100%;
  // color: $color-fff;
  justify-content: space-between;
  align-items: flex-start;
  position: absolute;
  left:189px;
  // margin-left: 335px;

  .input-search-bar {
    height: 74px;
    width: 600px;
    margin-bottom: 10px;
    // border: 4px solid rgba($col-theme, 0.2);
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background: #ffffff;

    /* 输入框头部 */
    .input-search-pre {
      //   height: 50px !important;
      width: 120px !important;
      color: #404040;
      font-size: 18px;
      background: $color-fff;
      .el-input__inner {
        text-align: center !important;
        border: none !important;
        border-radius: 0 !important;
        color: #333;
        background-color: #ffffff !important;
      }
    }

    /* 输入框内容 */
    .input-search {
      //   height: 50px !important;
      flex: 1;
      .el-input__inner {
        border: none !important;
        border-radius: 0 !important;
        background-color: #ffffff !important;
      }
      input::-webkit-input-placeholder {
        color: #999999;
      }
    }
    /* 输入框按钮 */
    .input-search-btn {
      background: linear-gradient(to right, #fd4040, #fd4040);
      height: 100%;
      width: 100px;
      border-radius: 0 !important;
      // border-top-right-radius: 4px !important;
      // border-bottom-right-radius: 4px !important;
      // border: 2px solid #b6a99c !important;
      text-align: center;
      font-size: $font-size18;
      color: $color-fff;
      cursor: pointer;
      .el-button {
        font-weight: bold !important;
      }
    }
  }
  .input-tips {
    .input-tips-item {
      font-size: $font-size16;
      height: $font-size16;
      line-height: $font-size16;
      color: #fff;
      padding: 0 10px;
      // color: $color-fff;
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

</style>
<style lang="scss" scoped>
.wrap-search {
  display: -webkit-flex;
  display: flex;
  align-items: center;
  border: 4px solid rgba(#ffffff, 0.5);
  border-radius: 4px;
  background-color: rgba(#ffffff, 0.5);

  ::v-deep .form-select,
  ::v-deep .input-search,
  ::v-deep .form-select-auto-complete {
    .el-input__inner {
      border: none;
    }
  }
  ::v-deep .form-select {
    width: 120px;

    .el-input__inner {
      border-top-left-radius: 4px !important;
      border-bottom-left-radius: 4px !important;
    }
  }
  ::v-deep .input-search,
  ::v-deep .form-select-auto-complete {
    width: 200px;
  }
  .el-button {
    width: 100px;
    font-size: 16px;
  }
}
::v-deep .el-input__inner {
  border: none;
  font-size: 18px;
}
</style>
